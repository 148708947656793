function NotFound() {
  return (
    <div className="content">
      <h1>404 - Not Found</h1>
      <h3>Sorry we couldn't find that page!</h3>
    </div>
  );
}

export default NotFound;
